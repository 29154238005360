












import CClassifiedSellerCard from '~/components/shared/configurable/classified/seller/CClassifiedSellerCard.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CClassifiedSellerCard },
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  computed: {
    subProfiles() {
      if (!this.profile?.sub_profiles?.length) {
        return [this.profile]
      }
      return (this.profile && this.profile.sub_profiles) || []
    }
  }
})
