











import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    url: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: null
    }
  }
})
